<template>
  <MasterDetail
    ref="masterDetail"
    :hasExportCSV="false"
    :hasExportXLSX="false"
    :opts="opts"
    :cols="cols"
    :customResource="customResource">
  </MasterDetail>
</template>

<script>
import MasterDetail from '@/components/master-detail.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
      MasterDetail,
  },
  // mixins: [],
  // directives: {},
   data() {
       return {
         opts: {
          },
       }
   },
  computed: {
    ...mapGetters(['clientId']),
    cols() {
      return [
        {
          key: 'id',
          name: 'Código',
          hideInform: true,
          hideInTable: true,
          width: '50px',
        },
        {
          key: 'nome',
          name: 'Nome',
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }],
        },
        {
          key: 'permissoes',
          name: 'Permissões',
          type: this.$fieldTypes.TEXT,
          rules: [{ rule: 'required' }],
        },
      ]
    },
    resourceUrl() {
      return `/v1/ativacaoped/${this.clientId}/nivel-hierarquicos`;
    },
    customResource() {
      const resource = this.apiResource(this.resourceUrl);
      const that = this;
      return {
        get(args) {
          return resource.get(args).then((result) => {
            return result.data;
          });
        },
        save(args) {
          return resource.save({...args}, args.id).then((result) => {
            that.$refs.masterDetail.doLoad();
            return result;
          })
        }
      }
    }
  },
  // filters: {},
  // created() {},
  // mounted() {},
  // updated() {},
  // destroyed() {},
   methods: {
   },
  // watch: {},
}
</script>

<style>

</style>
